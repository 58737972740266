import api from '../api';
import notifSystem from '../notifSystem';

export const archiveList = (query) =>
  api.paiements
    .custom('listArchive')
    .get(query)
    .then((e) => {
      const paiement = e.body().data();
      return paiement;
    })
    .catch((e) => {
      notifSystem.error('Erreur', e.message);
    });

export const getPaiements = (data) =>
  Promise.all([
    api.artisans.get(data.artisan),
    api.paiements.getAll({
      query: JSON.stringify({ id: data.id }),
    }),
    api.reglements.get(data.id),
  ]).then(([artisan, paiements, reglement]) => [
    artisan.body().data(),
    paiements.body().map(e => e.data()),
    reglement.body().data(),
  ]);

export const deletePaiement = (id) =>
  api.paiements.delete(id)
    .then(() => {
      notifSystem.success('Opération réussie',
        'Le paiement a bien été supprimé.');
    }).catch(() => {
      notifSystem.error('Erreur', 'Le paiement n\'a pas pu être supprimé.');
    });
